import { generalDomains } from './shared';
import { staging } from './data';
export const environment = {
    production: false,
    demo: true,
    isMobile: false,
    APIEndPoint: 'https://server.demo.strya.co/api/athletex/v1/',
    defaultCredentials: null,
    googleMapsApiKey: 'AIzaSyAZQJ41ocX-QZJvlDbThe1tlEV8fLRFXxY',
    hotjarSiteId: null,
    generalDomains,
    data: staging
};
