import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkToIconNamePipe } from './pipes/link-to-icon-name.pipe';
import { IfUserIsDirective } from './directives/if-user-is.directive';
import { IfAdminDirective } from './directives/if-admin.directive';
import { AddLinkPrefixPipe } from './pipes/add-link-prefix.pipe';
import { SafeUrlPipe } from './pipes/safe-html.pipe';
import { IfDirectoryDirective } from './directives/if-directory.directive';
import { IfIsOwnerDirective } from './directives/if-is-owner.directive';
import { SplitPipe } from './pipes/split.pipe';
import { TextSizeLimitPipe } from './pipes/text-limit.pipe';
import { TimestampParsePipe } from './pipes/timestamp-parse.pipe';
import { IfDirectoryIsDirective } from './directives/if-directory-is.directive';
import { SortByStatusPipe } from './pipes/sort-by-status.pipe';
import { SortTeamsPipe } from './pipes/sort-teams.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { GetUniqueSportsFromTeamsPipe } from './pipes/get-unique-sports-from-teams.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        LinkToIconNamePipe,
        IfUserIsDirective,
        IfAdminDirective,
        IfDirectoryDirective,
        AddLinkPrefixPipe,
        SafeUrlPipe,
        IfIsOwnerDirective,
        SplitPipe,
        IfIsOwnerDirective,
        TextSizeLimitPipe,
        TimestampParsePipe,
        SortByStatusPipe,
        IfDirectoryIsDirective,
        SortTeamsPipe,
        SortByPipe,
        GetUniqueSportsFromTeamsPipe
    ],
    exports: [
        LinkToIconNamePipe,
        IfUserIsDirective,
        IfAdminDirective,
        IfDirectoryDirective,
        AddLinkPrefixPipe,
        SafeUrlPipe,
        IfIsOwnerDirective,
        SplitPipe,
        IfIsOwnerDirective,
        TextSizeLimitPipe,
        TimestampParsePipe,
        SortByStatusPipe,
        IfDirectoryIsDirective,
        SortTeamsPipe,
        SortByPipe,
        GetUniqueSportsFromTeamsPipe
    ]
})
export class SharedModule { }
