<ion-list lines="none" class="sidebar-menu-item teams athletex-theme">

    <ion-list-header class="ion-no-padding">
        <div class="header-container">
            <span>Networks</span>

            <ion-buttons *ngIf="user.profile_completed && !isMobile">
                <ion-button (click)="navigateToTeamsListEdit($event)" class="side-menu-button" title="Team settings">
                    <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </ion-list-header>

    <ng-container *ngIf="!isMobile">
        <ion-item *ifDemo="+currentTeam.id !== 18"
            (click)="onTeamClicked()"
            class="ion-no-padding"
            [class.team-selected]='isGlobal'>

        <ion-avatar slot="start">
            <ion-icon name="globe-outline" style="height:100%; width: 100%">
            </ion-icon>
        </ion-avatar>

            <ion-text>
                <h5 class="network-name">Global</h5>
            </ion-text>

            <ion-buttons slot="end">
                <ion-button (click)="changeUserDirectoryType($event)" class="directory-type-identificator">
                    <ion-icon name="radio-button-on-outline"
                        [class.global]="user.global">
                    </ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-item>
    </ng-container>

    <ng-container *ngFor="let group of user.teams | sortTeams | sortByStatus | groupTeams">

        <ion-item *ngIf="group.parent && (group.items.length) > 1"
                  (click)="onGroupClicked(group)"
                  [class.clickable]="group.clickable"
            class="group-header">

            <ion-icon name="caret-down-outline"
                slot="start"
                class="mr-1">
            </ion-icon>

            <h5 class="network-name">
                {{ group.parent.name }}
            </h5>

        </ion-item>

        <ion-item *ngFor="let team of group.items; trackBy: trackByTeamId"
            (click)="onTeamClicked(team)"
            class="ion-no-padding"
            [class.grouped]="group.parent && (group.items.length > 1)"
            [class.team-selected]='!isGlobal && currentTeam.slug === team.slug'
            [style.cursor]="(!isGlobal && currentTeam.slug === team.slug && team.status !== 'active') ? 'default' : 'pointer'">

            <ng-container slot="start">
                <ion-icon *ngIf="group.school && (group.items.length > 1)"
                    name="caret-forward-outline"
                    class="indicator">
                </ion-icon>
                <ion-avatar>
                    <img [src]="team.logo_link" alt="">
                </ion-avatar>
            </ng-container>

            <ion-text>
                <h5 class="network-name">
                    {{team.name}}
                </h5>
            </ion-text>

            <ion-buttons *ngIf="team.admin" slot="end">
                <ion-button (click)="navigateToWaitList($event, team.slug)" class="side-menu-button" title="Waitlist">
                    <ion-icon name="accessibility-outline" style="color: rgb(0, 110, 213)"></ion-icon>
                </ion-button>
            </ion-buttons>

            <ion-buttons *ngIf="team.status === 'pending'" slot="end">
                <ion-button class="side-menu-button">
                    <ion-icon name="timer-outline"></ion-icon>
                </ion-button>
            </ion-buttons>

        </ion-item>

    </ng-container>
</ion-list>

